.App {
  text-align: center;
  background-color: #dff2f7;
  background-image: url('./zblogosoftblue.png');
  min-height: 138.89vh; /* 1000/720, used to fit to other screen sizes*/
  background-size: 50%; /* just enough so logo takes up screen*/
  height: auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.App-mobile{
  text-align: center;
  background-color: #dff2f7;
  background-image: url('./zblogosoftblue.png');
  background-position-y: 55px !important;
  /*Since react for now doesn't support passing !important in as a string,
   the most logical thing is to make this its own style. The other option
   is to define the style in a js file and use setProperty, which
   I really don't like*/
  min-height: 138.89vh; /* 1000/720, used to fit to other screen sizes*/
  background-size: 50%; /* just enough so logo takes up screen*/
  height: auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.header {
  background: #90ee90;
  height: 48px;
  width: 100%;
  top: 0%;
  position: fixed;
  overflow: hidden;
}

.footer{
  background: #90ee90;
  height: 48px;
  width: 100%;
  bottom: 0%;
  position: fixed;
  overflow: hidden;
}

.App-overflow {
  background: #dff2f7;
}

.close-button-mobile-wrapper{
  /* Note: this is identical to another, badly name wrapper.
  TODO: cleanup
   */
  text-align: center;
}

.close-button-mobile{
  /*display:flex;
  justify-content: center; */
  display: inline-block;
}

.modal-style{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  min-height: 50%;
  max-height: calc(100% - 96px); /* 2*48 for the bottom toolbar */
  transform: translate(-50%, -50%) !important;
  background: #dff2f7;
  overflow: auto;
}

.modal-mobile-style{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) !important;
  background: #dff2f7;
}

.modal-text{
  padding: 5%;
}

.embedded-bandcamp{
  width: 67vw; /* (500/1080) */
  height: 67vw; /* (500/720) */
}

.firstInTwoCentered{
  text-align: center;
}
.inline{
  display: inline-block;
}
.bridgeBetweenSections{
  position: relative;
  text-align:center;
  top: 75px;
  max-width: 1080px;
  margin: 0px auto;
  background-color: white;
  padding-bottom: 1px;
  z-index: 0;
}

.mobileBridgeBetweenSections{
  position: relative;
  text-align:center;
  top: 66vw;
  max-width: 1080px;
  margin: 0px auto;
  background-color: white;
  padding-bottom: 1px;
}

.secondInTwo{
  position: relative;
  margin-top: 100px;
}

.secondInTwoMobile{
  position: relative;
  margin-top: calc(100px + 66vw);
}

.hidden{
  display: none;
}

.unhidden{
  display: initial;
}


.makestack{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  position: fixed;
}

.makerows{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.iframe{
  height: 720px;
  display: initial;
  position: relative;
  width: 100%;
  max-width: 1080px !important;
  margin: auto;
}

.floatontop{
  z-index: 9999;
}